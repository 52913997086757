import { useStaticQuery, graphql } from "gatsby"

const ticketsPageDataQuery = graphql`
  query TicketsPageData {
    sanityTicketsPage {
      ...fieldsOnTicketsPageDocument
    }
  }
`

export const useTicketsPage = () => {
  const { sanityTicketsPage } = useStaticQuery(ticketsPageDataQuery)

  return sanityTicketsPage
}

export default useTicketsPage
