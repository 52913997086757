import union from "lodash.union"

/* Import Global Hooks */
import { useGalleries } from "./useGalleries"

/* Only Get Years that are associated with Galleries*/

export const useGalleryYears = filterYear => {
  const galleries = useGalleries({})

  let miamiBeachYears = galleries.reduce((accumulator, gallery) => {
    const galleryYears = gallery.years.filter(y => y.fairLocation != 'houston').map(({ year }) => year)
    return union(accumulator, galleryYears)
  }, [])

  /* sort in ascending order*/
  if(miamiBeachYears.length > 1){
    miamiBeachYears = miamiBeachYears.map(y => parseInt(y)).sort().reverse()
  }

  let houstonYears = galleries.reduce((accumulator, gallery) => {
    const galleryYears = gallery.years.filter(y => y.fairLocation == 'houston').map(({ year }) => year)
    return union(accumulator, galleryYears)
  }, [])

  /* sort in ascending order*/
  if(houstonYears.length > 1){
    houstonYears = houstonYears.map(y => parseInt(y)).sort().reverse()
  }
  

  return {miamiBeachYears, houstonYears }
}

export default useGalleryYears
