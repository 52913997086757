import { useStaticQuery, graphql } from "gatsby"
import find from "lodash.find"

const galleriesDataQuery = graphql`
  query GalleriesData {
    allSanityGallery(
      filter: { publish: { eq: true } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        ...fieldsOnGalleryDocument
      }
    }
  }
`
// Fix for GraphQL sorting in a 'Case Sensitive' Manner by Name
const sortGalleries = (a, b) => {
  a = a.name.toLowerCase()
  b = b.name.toLowerCase()
  if (a == b) return 0
  // return true
  return a < b ? -1 : 1
}

export const useGalleries = ({year, fairLocation }) => {
  
  const filterYear = year

  let {
    allSanityGallery: { nodes: galleries },
  } = useStaticQuery(galleriesDataQuery)

  galleries = galleries.sort(sortGalleries)

  return filterYear
    ? galleries.filter(gallery => {
        
        const matchingYear = find(gallery.years, (y) => {

          return y.year == filterYear && (y.fairLocation == fairLocation || (fairLocation == 'miamiBeach' && y.fairLocation === null))
        })
        return matchingYear
      })
    : galleries
}

export default useGalleries
